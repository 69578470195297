import './home.css'; // Import the CSS file
import logo from '../logo/logo.png'; // Make sure the path to your logo is correct

const Home = () => {
    return (
        <div className="home-container">
            <img src={logo} alt="Logo" className="home-logo" />
        </div>
    );
};

export default Home;
