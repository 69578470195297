import React from 'react';
import './navbar.css';
import logo from '../logo/icon.png'; // Correct the path to your logo file

const Navbar = () => {
    return (
        <nav className="navbar">
            
            <ul className="nav-links">
                <li>
                    <a href="/">
                        <div className="logo-container">
                            <img src={logo} alt="Logo" className="logo" />
                        </div>
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Navbar;
